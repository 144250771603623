.faucet-container {
    max-width: 80%;
    margin: 50px auto;
    padding: 20px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    text-align: center;
    min-height: 60vh !important;
}

input {
    width: 95%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

button {
    padding: 10px 15px;
    margin-top: 20px;
    border: none;
    background-color: #3498db;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.2s ease;
}

button:hover {
    background-color: #2980b9;
}

.response-message {
    margin-top: 20px;
    color: red;
}
